export const REACT_QUERY_KEY = {
	GET_ALL_LICENCES: 'GET_ALL_LICENCES',
	GET_QUERY_RANGE: 'GET_QUERY_RANGE',
	GET_ALL_DASHBOARDS: 'GET_ALL_DASHBOARDS',
	GET_TRIGGERED_ALERTS: 'GET_TRIGGERED_ALERTS',
	DASHBOARD_BY_ID: 'DASHBOARD_BY_ID',
	GET_BILLING_USAGE: 'GET_BILLING_USAGE',
	GET_FEATURES_FLAGS: 'GET_FEATURES_FLAGS',
	DELETE_DASHBOARD: 'DELETE_DASHBOARD',
	LOGS_PIPELINE_PREVIEW: 'LOGS_PIPELINE_PREVIEW',
	ALERT_RULE_DETAILS: 'ALERT_RULE_DETAILS',
	ALERT_RULE_STATS: 'ALERT_RULE_STATS',
	ALERT_RULE_TOP_CONTRIBUTORS: 'ALERT_RULE_TOP_CONTRIBUTORS',
	ALERT_RULE_TIMELINE_TABLE: 'ALERT_RULE_TIMELINE_TABLE',
	ALERT_RULE_TIMELINE_GRAPH: 'ALERT_RULE_TIMELINE_GRAPH',
	GET_CONSUMER_LAG_DETAILS: 'GET_CONSUMER_LAG_DETAILS',
	TOGGLE_ALERT_STATE: 'TOGGLE_ALERT_STATE',
	GET_ALL_ALLERTS: 'GET_ALL_ALLERTS',
	REMOVE_ALERT_RULE: 'REMOVE_ALERT_RULE',
	DUPLICATE_ALERT_RULE: 'DUPLICATE_ALERT_RULE',
	UPDATE_ALERT_RULE: 'UPDATE_ALERT_RULE',
};
